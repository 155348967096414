import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";
import NaviNext from "../components/NaviNext";
import { StaticImage } from "gatsby-plugin-image";

const Part0 = () => {
  return (
    <Tail>
      <Hero>
        <h1>ОБРАЩЕНИЕ ГЕНЕРАЛЬНОГО ДИРЕКТОРА ОБЩЕСТВА</h1>
      </Hero>
      <TextBlock>
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-10">
          Уважаемые коллеги и деловые партнеры!
        </h2>
        <article>
          <div className="md:max-w-[350px] lg:max-w-[400px] mb-5 ml-5 float-right">
            <StaticImage
              src="../images/part-05/people/gasandgariev.jpg"
              loading="eager"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <p>
            Прошедший 2021 год стал знаковым для энергетической отрасли страны:
            доля ТЭК составила четверть ВВП страны, растут объемы добычи и
            генерации энергетических ресурсов. Экономический подъем,
            актуализация климатической и технологической повесток обусловили
            необходимость действовать на основе взвешенных и продуманных
            решений, стремиться к максимальной синергии усилий государства,
            бизнеса и общества.
          </p>
          <p>
            Для АО «МОСГАЗ» обеспечение бесперебойного газоснабжения
            энергетических объектов и жителей Москвы всегда было и остается
            первоочередной задачей. В 2021 году впервые с 2013 года в Москве
            произошло увеличение уровня потребления газа. Объем транспортировки
            превысил 24,8 млрд м3. Коллектив Общества продемонстрировал
            слаженную и высокоэффективную работу: производственная программа
            выполнена в полном объеме, достигнуты все ключевые показатели
            развития предприятия.
          </p>
          <p>
            Продолжилась работа по снижению уровня износа газовых сетей:
            строительство и реконструкция газопроводов, техническое
            перевооружение пунктов редуцирования газа, оборудование для которых
            изготавливается на собственных производственных мощностях АО
            «МОСГАЗ». Благодаря внедрению современного газового оборудования
            обновленные ГРП экологически безопасны, работают без шума, запаха
            газа и выбросов в воздух вредных веществ.
          </p>
          <p>
            Развивается сеть автоматизированных запорных устройств: за прошедший
            год установлены 96 крановых узлов с системой дистанционного
            управления, а общее количество АЗУ, управляемых из центральной
            диспетчерской Общества, превысило отметку в тысячу единиц.
          </p>
          <p>
            Большое внимание в 2021 году уделялось техническому обслуживанию
            внутридомового газового оборудования. Ежегодно проводя колоссальную
            работу, Обществу удается оставаться гарантом стабильности
            социального благополучия и экономического роста. Мы слышим пожелания
            каждого москвича, создаем новые, высокотехнологичные сервисы для
            удобства жителей столицы. Например, чат-бот в социальных сетях,
            принимающий заявки от пользователей, интеграция графика ТО ВДГО с
            голосовым помощником «Алиса».
          </p>
          <p>
            Перешагнула экватор программа реконструкции газопроводов-дюкеров:
            шесть объектов – Автозаводский, Даниловский, Химкинский,
            Новодевичий, Тушинский и Рубцовский – полностью завершены. Выбранные
            нашими специалистами технологические решения доказали свою
            эффективность, реконструированные газопроводы успешно функционируют
            в составе газораспределительной системы Москвы.
          </p>
          <p>
            В течение всего года успешно продолжалась работа по развитию системы
            газоснабжения Троицкого и Новомосковского административных округов
            Москвы: к концу 2021 года на данной территории построено 83,9 км
            газопроводов высокого давления. Введен в эксплуатацию ГРП
            «Ликовский», который обеспечивает газом существующие объекты и
            перспективную застройку на территории семи поселений ТиНАО:
            Внуковского, Марушкинского, Кокошкино, Первомайского,
            Филимонковского, Десеновского и Московского. За этой программой –
            будущее нашего предприятия, но главное – будущее сотен тысяч людей,
            проживающих в этой местности. Газ дает колоссальный толчок развитию
            этих мест, открывает возможности для новых проектов промышленного и
            жилищного строительства, а значит, способствует повышению
            инвестиционной привлекательности данных территорий.
          </p>
          <p>
            Результаты деятельности газовой отрасли еще раз подтверждают
            динамичный характер развития Общества, правильность выбранных нами
            приоритетов и обозначенных целей, а также высокий профессионализм и
            слаженность работы коллектива АО «МОСГАЗ». Мы создаем на предприятии
            атмосферу взаимного доверия, делаем все, чтобы наши специалисты были
            уверены в благополучном будущем: своем, своей семьи, нашего
            предприятия, Москвы и всей России. В сложные времена особенно важно
            проявлять милосердие и заботу к ближним, помогать тем, кому
            приходится тяжелее всего. В 2021 году мы провели четыре донорских
            акции: сотрудники Общества сдавали кровь для маленьких пациентов
            Морозовской детской городской клинической больницы, Российской
            детской клинической больницы и отделений переливания крови других
            крупных столичных больниц. Многие сотрудники стали донорами плазмы
            для лечения пациентов с коронавирусной инфекцией. Регулярное
            донорство является частью корпоративной культуры АО «МОСГАЗ».
          </p>
          <p>
            Наша главная задача – использовать те окна возможностей, которые
            открываются для газовой отрасли. АО «МОСГАЗ» ориентировано на
            развитие отечественных комплексных систем и сервисов. Нам предстоит
            масштабная работа по освоению производства собственного
            оборудования, введение новых интеллектуальных программ и компетенций
            для поддержания высочайшего уровня квалификации сотрудников.
            Убежден, что перспективные проекты Общества и плодотворное
            сотрудничество с нашими отраслевыми коллегами позволят не только
            удержать высокую планку, заданную в предыдущие годы работы, но и
            укрепить позиции газового хозяйства столицы.
          </p>
          <p>
            Генеральный директор АО «МОСГАЗ», <br />
            заслуженный энергетик России <br />
            Г.Г. Гасангаджиев
          </p>
        </article>

        <NaviNext next="/part-1" title="РАЗДЕЛ 1. ОБЩИЕ СВЕДЕНИЯ"></NaviNext>
      </TextBlock>
    </Tail>
  );
};

export default Part0;

export const Head = () => (
  <Seo title="ОБРАЩЕНИЕ ГЕНЕРАЛЬНОГО ДИРЕКТОРА ОБЩЕСТВА" />
);
